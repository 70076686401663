/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('./app/app.component.css');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-family: 'Sofia Sans', sans-serif;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Sofia Sans', sans-serif;
  padding: 0;
}